import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";

function ProjectPage() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      <MKBox component="main" py={6}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MKBox
                sx={{
                  backgroundImage: 'url("/pavones.png")',
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0.75rem",
                  height: "800px",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                  padding: "3rem",
                }}
              >
                <MKTypography variant="h1" fontWeight="bold" color="black">
                  Pavones
                </MKTypography>
                <Card>
                  <MKBox p={2}>
                    <MKTypography variant="h6">Weather Today</MKTypography>
                    <MKTypography variant="body2">Pavones, 33°C</MKTypography>
                    <MKBox component="img" src="https://bit.ly/36as930" alt="sunny" width="40px" />
                  </MKBox>
                </Card>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <MKBox mb={2}>
                <MKTypography variant="body1">
                  Grassroots conservation project to practice permaculture and mindful practices of
                  living in harmony with the largest wave in the western hemisphere.
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <MKBox p={3}>
                  <MKTypography variant="h6">Pavones conservation</MKTypography>
                  <MKTypography variant="body2" color="text" mb={2}>
                    Come and join us in our efforts to conserve the beautiful land of Pavones
                  </MKTypography>
                  <MKButton variant="outlined" color="info" size="small">
                    View trees to buy
                  </MKButton>
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MKBox p={3} position="relative">
                  <video width="100%" height="1080" autoPlay loop muted>
                    <source src="/nature1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <MKBox position="absolute" top={0} left={0} p={15} color="#ffffff">
                    <MKTypography variant="h1" fontWeight="bold" color="white">
                      Pavones is LIDAR Monitored
                    </MKTypography>
                    <MKTypography variant="h5" color="white">
                      This means we can easily understand and detect changes that happen to Pavones
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MKBox p={15}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <MKTypography variant="h1" fontWeight="bold">
                        Cameras that bring you closer to Pavones
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <iframe
                        width="100%"
                        height="815"
                        src="https://www.youtube.com/embed/1gnACxpM88U?si=xo_xfHvExheFNxO8?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                      ></iframe>
                    </Grid>
                  </Grid>
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MKBox p={3} position="relative">
                  <MKBox
                    component="img"
                    src="/PavonesSample.png"
                    alt="Pavones coast sample"
                    width="100%"
                    height="2080"
                  />
                  <MKBox position="absolute" top={0} left={0} p={15} color="#000000">
                    <MKTypography variant="h1" fontWeight="bold" color="white">
                      Monitored from space
                    </MKTypography>
                    <MKTypography variant="h5" color="white">
                      Using satellite imagery from Planet, Sentinel-2 and Landsat 8
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ProjectPage;
