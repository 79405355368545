import { useEffect, useRef } from "react";
import Typed from "typed.js";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

import bgImageTwo from "assets/images/scanmovie.mp4";
import lasHuacasImage from "assets/images/LasHuacas.png";
import limonImage from "assets/images/limon.png";
import pavonesImage from "assets/images/pavones.png";
import co2video from "assets/images/globe.mov";
import testimony from "assets/images/testimony.png";
import Asample from "assets/images/America.png";

function Presentation() {
  const typedJSRef = useRef(null);

  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["farmers", "scientists", "environmentalists", "activists"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  const projects = [
    {
      image: lasHuacasImage,
      title: "Las Huacas",
      description:
        "Reforestation Hacienda Las Huacas transformative environmental project that transitions 400 hectares of pastureland in Costa Rica into thriving forests.",
      categories: ["400 hectares", "Pastureland", "Costa Rica"],
      action: {
        type: "internal",
        route: "/pages/presentation/product-page",
        color: "info",
        label: "Learn More",
      },
    },
    {
      image: limonImage,
      title: "Limon",
      description: "Reforestation besides Pineapple plantation",
      categories: ["Reforestation", "Pineapple Plantation"],
      action: {
        type: "internal",
        route: "/somewhere",
        color: "info",
        label: "Learn More",
      },
    },
    {
      image: pavonesImage,
      title: "Pavones",
      description: "Ecosystemic conservation besides one of the largest waves in the world",
      categories: ["Ecosystemic Conservation", "Largest Waves"],
      action: {
        type: "internal",
        route: "/somewhere",
        color: "info",
        label: "Learn More",
      },
    },
  ];

  const getCornerStyle = (index) => {
    const positions = [
      { top: "205%", left: "41%" },
      { top: "105%", right: "5%" },
      { bottom: "-181%", left: "5%" },
      { bottom: "1%", right: "5%" },
    ];
    return positions[index % positions.length];
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      <MKBox minHeight="75vh" width="100%" sx={{ display: "grid", placeItems: "center" }}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/SArICb_eQwA?start=4&end=30&autoplay=1&mute=1&loop=1&playlist=SArICb_eQwA"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{ position: "absolute", top: 0, left: 0 }}
        />

        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Grid container item xs={12} lg={8} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Carbon offsetting powered by community
            </MKTypography>
            <MKTypography variant="body1" color="white" px={1} mt={1}>
              A platform to value and invest in nature through community building. Employing
              forensic sequestration, we enable ecological restoration—one tree at a time.
            </MKTypography>

            <Grid container justifyContent="center">
              <MKButton
                variant="gradient"
                color="info"
                size="large"
                href="/pages/presentation/carbon-dashboard"
              >
                Get Started
              </MKButton>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox component="header" position="relative">
        <MKBox
          display="flex"
          alignItems="center"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
              `${linearGradient(rgba(gradients.info.main, 0.5), rgba(gradients.info.state, 0.5))}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            zIndex: 1,
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 0,
              opacity: 0.9,
            }}
          >
            <source src={bgImageTwo} type="video/mp4" />
          </video>
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mx="auto"
            >
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Guerilla <span ref={typedJSRef} /> on the ground
              </MKTypography>
              <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
                who believe in a bottom up restoration of the carbon cycle.
              </MKTypography>
              <MKButton color="white">contact us</MKButton>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>

      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundColor: "rgb(252, 252, 252)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <MKTypography
          variant="h6"
          sx={{
            position: "absolute",
            top: "15%",
            zIndex: 2,
            "& .MuiTypography-root": {
              fontSize: "0.8rem",
            },
          }}
        >
          <span style={{ fontSize: "0.8rem" }}>01</span>
          <br />
          Curate projects
        </MKTypography>
        <MKTypography
          variant="h6"
          sx={{
            position: "absolute",
            right: "25%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            "& .MuiTypography-root": {
              fontSize: "0.8rem",
            },
          }}
        >
          <span style={{ fontSize: "0.8rem" }}>02</span>
          <br />
          Build community
        </MKTypography>
        <MKTypography
          variant="h6"
          sx={{
            position: "absolute",
            bottom: "15%",
            zIndex: 2,
            "& .MuiTypography-root": {
              fontSize: "0.8rem",
            },
          }}
        >
          <span style={{ fontSize: "0.8rem" }}>03</span>
          <br />
          Implement technology
        </MKTypography>
        <MKTypography
          variant="h6"
          sx={{
            position: "absolute",
            left: "25%",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 2,
            "& .MuiTypography-root": {
              fontSize: "0.8rem",
            },
          }}
        >
          <span style={{ fontSize: "0.8rem" }}>04</span>
          <br />
          Plant trees
        </MKTypography>
        <MKBox
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <video
            src={co2video}
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "1280px",
              margin: "0 auto",
              display: "block",
              zIndex: 1,
            }}
          />
          <MKTypography
            variant="body2"
            color="black"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "left",
              zIndex: 2,
              maxWidth: "60%",
            }}
          >
            Explore projects with profound causes, where cutting-edge <br />
            technology and human collaboration come together
            <br />
            These projects connect you directly with project <br />
            owners, empowering small players to make a <br />
            global impact in restoring the carbon cycle <br />
            through advanced technologies for surveying, monitoring, and <br />
            connecting.
          </MKTypography>
        </MKBox>
      </MKBox>
      <MKBox
        py={3}
        sx={{
          backgroundImage: `url(${Asample})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <Container
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: "1.5rem",
          }}
        >
          <MKTypography variant="h2" align="left" mb={4} color="white">
            Democratising the carbon market
          </MKTypography>
          <MKTypography variant="body2" mb={6} color="white">
            Our mission is to empower project owners <br />
            and farmers, regardless of size, by providing access <br />
            to advanced technology. This enables them to verify <br />
            their projects and qualify for carbon sequestration, ensuring <br />
            equitable participation in the carbon market.
          </MKTypography>

          {projects.map((project, index) => (
            <Box
              key={index}
              sx={{
                position: "absolute",
                ...getCornerStyle(index),
                width: "300px",
                height: "300px",
              }}
            >
              <SimpleBookingCard
                image={project.image}
                title={project.title}
                description={project.description}
                categories={project.categories}
                action={project.action}
                sx={{
                  width: "100%",
                  height: "100%",
                  "& .MuiBox-root": {
                    width: "100%",
                    height: "100%",
                  },
                  "& img": {
                    height: "100%",
                    objectFit: "cover",
                  },
                  "& .MuiTypography-root": {
                    fontSize: "0.8rem",
                  },
                  transition: "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    cursor: "pointer",
                    zIndex: 10,
                  },
                }}
              />
            </Box>
          ))}
        </Container>
      </MKBox>
      <MKBox
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${testimony})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <MKBox
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <MKTypography
            variant="h2"
            color="white"
            sx={{
              textAlign: "center",
              zIndex: 1,
            }}
          />
        </MKBox>
      </MKBox>
      <MKBox
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MKBox
          sx={{
            position: "absolute",
            top: "2rem",
            right: "2rem",
            zIndex: 1,
          }}
        >
          <MKTypography variant="h1" color="white">
            Beyond Carbon Offsetting:
          </MKTypography>
          <MKTypography variant="h1" color="white">
            Enriching Your Contribution
          </MKTypography>
          <MKTypography
            variant="body2"
            color="white"
            sx={{
              maxWidth: "1080px",
              mt: 2,
              opacity: 0.95,
              textAlign: "left",
            }}
          >
            Our deep connection with each project ensures that the benefits of sponsoring trees
            extend far beyond reducing your carbon footprint.
            <br />
            Enjoy the unique rewards of local produce, volunteer opportunities, and a meaningful
            connection to the communities you support.
            <br />
            Your investment creates lasting, tangible impacts that go beyond environmental
            restoration.
          </MKTypography>
        </MKBox>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube-nocookie.com/embed/Jmlc2tm1HY0?autoplay=1&mute=1&loop=1&playlist=Jmlc2tm1HY0&controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            border: "none",
            pointerEvents: "none",
          }}
        />
      </MKBox>
    </>
  );
}

export default Presentation;
