import React, { useEffect, useRef } from "react";
import L from "leaflet";
import * as h3 from "h3-js";
import "leaflet/dist/leaflet.css";

const MapComponent = React.memo(() => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = L.map(mapRef.current).setView([10.584233, -85.394647], 18);

      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        {
          maxZoom: 19,
          attribution: "Tiles &copy; Esri",
        }
      ).addTo(mapInstanceRef.current);

      // Add H3 grid
      const bounds = mapInstanceRef.current.getBounds();
      const hexagons = generateH3Hexagons(bounds);
      L.geoJSON(hexagons, {
        style: (feature) => ({
          fillColor: feature.properties.fillColor,
          fillOpacity: 0.7,
          color: feature.properties.fillColor,
          weight: 1,
        }),
      }).addTo(mapInstanceRef.current);
    }

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, []);

  const generateH3Hexagons = (bounds) => {
    const hexagons = [];
    for (let i = 0; i < 1000; i++) {
      const lat = bounds.getSouth() + (bounds.getNorth() - bounds.getSouth()) * Math.random();
      const lng = bounds.getWest() + (bounds.getEast() - bounds.getWest()) * Math.random();
      const hex = h3.latLngToCell(lat, lng, 12);
      const coordinates = h3.cellToBoundary(hex, true);
      const co2Sequestration = Math.floor(Math.random() * 1000);
      const fillColor = getHeatmapColor(co2Sequestration);
      hexagons.push({
        type: "Feature",
        properties: {
          co2Sequestration: co2Sequestration,
          fillColor: fillColor,
        },
        geometry: {
          type: "Polygon",
          coordinates: [coordinates],
        },
      });
    }
    return hexagons;
  };

  const getHeatmapColor = (value) => {
    const heatmapColors = ["#00ff0066", "#ffff0066", "#ff000066"];
    if (value < 300) return heatmapColors[0];
    if (value < 700) return heatmapColors[1];
    return heatmapColors[2];
  };

  return <div ref={mapRef} style={{ height: "400px", width: "100%" }} />;
});

export default MapComponent;
