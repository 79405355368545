import React, { useState } from "react";
import { Grid, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { Nature, Landscape, Spa } from "@mui/icons-material";
import Co2Icon from "@mui/icons-material/Co2";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Otis Kit PRO Examples
import SimpleBookingCard from "examples/Cards/BookingCards/SimpleBookingCard";

// Image imports
import AntesdeLaurel from "assets/images/AntesdeLaurel.jpeg";
import cocobolo from "assets/images/cocobolo.jpeg";
import Guachipelin from "assets/images/Guachipelin.jpeg";
import Laurel from "assets/images/Laurel.jpeg";
import Madero from "assets/images/Madero.jpeg";
import Madronho from "assets/images/Madronho.jpeg";

const trees = [
  {
    name: "Antes de Laurel",
    scientificName: "Cordia alliodora",
    image: AntesdeLaurel,
    price: 29.99,
    co2Sequestration: "10.4 kg CO2/year",
    description: "Native tree with rapid growth and nitrogen fixation.",
    habitat: "Humid tropical forests, secondary forests, agroforestry.",
    benefits: "Timber, soil fertility, biodiversity enhancement.",
    ecosystemicInteractions: "Hosts epiphytes, provides shelter and food for wildlife.",
    biodiversityScore: 4,
    ecosystemicServices: [
      {
        title: "Carbon Sequestration",
        description: "Sequesters carbon in biomass.",
        icon: <Co2Icon />,
      },
      {
        title: "Habitat Provision",
        description: "Supports diverse wildlife.",
        icon: <Nature />,
      },
      {
        title: "Soil Enrichment",
        description: "Improves soil fertility and structure.",
        icon: <Landscape />,
      },
    ],
  },
  {
    name: "Cocobolo",
    scientificName: "Dalbergia retusa",
    image: cocobolo,
    price: 35.99,
    co2Sequestration: "14.7 kg CO2/year",
    description: "Highly valued hardwood tree with dense, durable wood.",
    habitat: "Dry tropical forests, rocky and well-drained areas.",
    benefits: "High-quality timber, erosion control, biodiversity support.",
    ecosystemicInteractions: "Provides habitat, maintains soil stability.",
    biodiversityScore: 5,
    ecosystemicServices: [
      {
        title: "Carbon Storage",
        description: "Stores carbon in dense wood.",
        icon: <Co2Icon />,
      },
      {
        title: "Biodiversity Support",
        description: "Supports diverse plant and animal species.",
        icon: <Nature />,
      },
      {
        title: "Erosion Control",
        description: "Deep roots prevent soil erosion.",
        icon: <Landscape />,
      },
    ],
  },
  {
    name: "Guachipelin",
    scientificName: "Diphysa americana",
    image: Guachipelin,
    price: 24.99,
    co2Sequestration: "12.3 kg CO2/year",
    description: "Versatile tree with nitrogen fixation and agroforestry uses.",
    habitat: "Dry and humid tropical forests, degraded soils.",
    benefits: "Soil fertility, fodder, firewood, timber.",
    ecosystemicInteractions: "Supports soil health, provides wildlife habitat.",
    biodiversityScore: 4,
    ecosystemicServices: [
      {
        title: "Carbon Offsetting",
        description: "Efficient CO2 sequestration.",
        icon: <Co2Icon />,
      },
      {
        title: "Wildlife Habitat",
        description: "Shelter and food for local fauna.",
        icon: <Nature />,
      },
      {
        title: "Soil Improvement",
        description: "Leaf litter and roots improve soil.",
        icon: <Landscape />,
      },
    ],
  },
  {
    name: "Laurel",
    scientificName: "Cordia alliodora",
    image: Laurel,
    price: 27.99,
    co2Sequestration: "11.2 kg CO2/year",
    description: "Fast-growing tree providing shade and shelter.",
    habitat: "Humid tropical forests, agroforestry, coffee plantations.",
    benefits: "Timber, biodiversity conservation, habitat provision.",
    ecosystemicInteractions: "Canopy for understory, wildlife habitat, nutrient cycling.",
    biodiversityScore: 4,
    ecosystemicServices: [
      {
        title: "Carbon Capture",
        description: "Captures atmospheric CO2.",
        icon: <Co2Icon />,
      },
      {
        title: "Canopy Provision",
        description: "Shade and shelter for plants and animals.",
        icon: <Nature />,
      },
      {
        title: "Nutrient Cycling",
        description: "Enhances ecosystem health.",
        icon: <Spa />,
      },
    ],
  },
  {
    name: "Madero",
    scientificName: "Gliricidia sepium",
    image: Madero,
    price: 31.99,
    co2Sequestration: "13.8 kg CO2/year",
    description: "Fast-growing tree with nitrogen fixation.",
    habitat: "Dry and humid tropical forests, agroforestry, living fences.",
    benefits: "Fodder, firewood, timber, soil fertility, erosion control.",
    ecosystemicInteractions: "Nitrogen fixation, food and shelter for wildlife.",
    biodiversityScore: 5,
    ecosystemicServices: [
      {
        title: "Carbon Sequestration",
        description: "Sequesters significant CO2.",
        icon: <Co2Icon />,
      },
      {
        title: "Wildlife Support",
        description: "Food and shelter for diverse species.",
        icon: <Nature />,
      },
      {
        title: "Nutrient Cycling",
        description: "Supports ecosystem health.",
        icon: <Spa />,
      },
    ],
  },
  {
    name: "Madrono",
    scientificName: "Calycophyllum candidissimum",
    image: Madronho,
    price: 33.99,
    co2Sequestration: "12.9 kg CO2/year",
    description: "Beautiful tree with ecological importance.",
    habitat: "Dry tropical forests, various soils, riparian areas.",
    benefits: "Nectar for pollinators, biodiversity habitat.",
    ecosystemicInteractions: "Supports pollination, provides habitat, stabilizes soil.",
    biodiversityScore: 4,
    ecosystemicServices: [
      {
        title: "Carbon Capture",
        description: "Mitigates climate change.",
        icon: <Co2Icon />,
      },
      {
        title: "Biodiversity Enhancement",
        description: "Supports diverse species.",
        icon: <Nature />,
      },
      {
        title: "Soil Stabilization",
        description: "Roots prevent erosion.",
        icon: <Landscape />,
      },
    ],
  },
];

const TreeBuyingPage = () => {
  const [selectedTree, setSelectedTree] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const handleTreeClick = (tree) => {
    setSelectedTree(tree);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedTree(null);
    setDialogOpen(false);
    setQuantity(1);
  };

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => Math.max(prevQuantity + change, 1));
  };

  return (
    <MKBox component="section" py={12}>
      <MKBox px={3}>
        <MKButton
          variant="outlined"
          color="info"
          size="small"
          sx={{ mb: 2 }}
          href="/ecommerce/products/product-page"
        >
          Back to project
        </MKButton>
        <MKTypography variant="h2" mb={5}>
          Trees at Las Huacas
        </MKTypography>
        <Grid container spacing={3}>
          {trees.map((tree) => (
            <Grid item xs={12} sm={6} md={4} key={tree.name}>
              <SimpleBookingCard
                image={tree.image}
                title={tree.name}
                description={tree.description}
                categories={[tree.scientificName, `$${tree.price}`, `${tree.co2Sequestration}`]}
                action={{
                  type: "internal",
                  route: "#",
                  color: "info",
                  label: "Learn More",
                  onClick: () => handleTreeClick(tree),
                }}
              />
            </Grid>
          ))}
        </Grid>
      </MKBox>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <MKTypography variant="h3">{selectedTree?.name}</MKTypography>
        </DialogTitle>
        <DialogContent>
          {selectedTree && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <MKBox
                  component="img"
                  src={selectedTree.image}
                  alt={selectedTree.name}
                  sx={{
                    width: "100%",
                    borderRadius: "lg",
                    boxShadow: 3,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MKTypography variant="body1" color="text" mb={2}>
                  {selectedTree.description}
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={2}>
                  Habitat: {selectedTree.habitat}
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={2}>
                  Benefits: {selectedTree.benefits}
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={2}>
                  CO2 Sequestration: {selectedTree.co2Sequestration}
                </MKTypography>
                <MKTypography variant="body1" color="text" mb={2}>
                  Biodiversity Score: {selectedTree.biodiversityScore}
                </MKTypography>
                <MKBox display="flex" alignItems="center" mt={3}>
                  <MKButton
                    variant="outlined"
                    color="info"
                    onClick={() => handleQuantityChange(-1)}
                  >
                    -
                  </MKButton>
                  <MKTypography variant="h6" mx={2}>
                    {quantity}
                  </MKTypography>
                  <MKButton variant="outlined" color="info" onClick={() => handleQuantityChange(1)}>
                    +
                  </MKButton>
                </MKBox>
              </Grid>
              <Grid item xs={12}>
                <MKTypography variant="h5" mt={3} mb={2}>
                  Ecosystemic Services
                </MKTypography>
                <Grid container spacing={2}>
                  {selectedTree.ecosystemicServices.map((service, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <MKBox p={2} textAlign="center" sx={{ boxShadow: 3, borderRadius: "lg" }}>
                        {service.icon}
                        <MKTypography variant="h6" mt={2} mb={1}>
                          {service.title}
                        </MKTypography>
                        <MKTypography variant="body2" color="text">
                          {service.description}
                        </MKTypography>
                      </MKBox>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <MKButton
            variant="gradient"
            color="info"
            onClick={() => {
              window.location.href = "/pages/referral";
            }}
          >
            Plant {quantity} trees - ${(selectedTree?.price * quantity).toFixed(2)}
          </MKButton>
          <MKButton onClick={handleDialogClose} color="secondary">
            Cancel
          </MKButton>
        </DialogActions>
      </Dialog>
    </MKBox>
  );
};

export default TreeBuyingPage;
