import React, { useState } from "react";
import PropTypes from "prop-types";
//import { loadStripe } from "@stripe/stripe-js";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import Box from "@mui/material/Box";
//import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadIcon from "@mui/icons-material/Download";

// Import new components
import DynamicCheckoutCard from "components/DynamicCheckoutCard";
import VolunteerForm from "components/VolunteerForm";

// Import images and other assets
import fotosaereas03 from "assets/images/LasHuacas/fotosaereas/03.jpeg";
import faunaInsect from "assets/images/LasHuacas/fauna/insect.jpg";
import faunaMono from "assets/images/LasHuacas/fauna/mono.jpg";
import faunaPziote from "assets/images/LasHuacas/fauna/pziote.jpg";
import faunaSloth from "assets/images/LasHuacas/fauna/sloth.jpg";
import faunaTigrillo from "assets/images/LasHuacas/fauna/tigrillo.jpg";
import faunaZorro from "assets/images/LasHuacas/fauna/zorro.jpg";
import lasHuacasGIF from "assets/images/LasHuacasGIF.mp4";
import reforestacionCIMG1484 from "assets/images/LasHuacas/reforestacion/CIMG1484.JPG";
import reforestacionCIMG1477 from "assets/images/LasHuacas/reforestacion/CIMG1477.JPG";
import mapasForestCover from "assets/images/LasHuacas/Mapas/ForestCover.png";
import multitemporal20170425 from "assets/images/multitemporal/20170425.png";
import multitemporal20171229 from "assets/images/multitemporal/20171229.png";
import multitemporal20190418 from "assets/images/multitemporal/20190418.png";
import multitemporal20191227 from "assets/images/multitemporal/20191227.png";
import multitemporal20240426 from "assets/images/multitemporal/20240426.png";
import multitemporalApril171924 from "assets/images/multitemporal/MultitemporalApril17,19,24.png";
import LasHuacasZoom from "assets/images/LasHuacas/LasHuacasZoom.mp4";
import LiDARdiagram from "assets/images/LiDARdiagram.png";
import Dronediagram from "assets/images/Dronediagram.png";
import SatelliteDiagram from "assets/images/Dronediagram.png";
import GroundSampleDiagram from "assets/images/Dronediagram.png";
import MKProgress from "components/MKProgress";
//const stripePromise = loadStripe(
//  "pk_test_51OAZLXIFktvR7sI0HSbXZN2witNHB1vQbwbi8MlRDkopBSG85aPWUJFk0EraKAGHUOumVcBdDDBApw9ghJLFDNyU00apgJ8HW6"
//)

const VerificationButton = styled(MKButton)(({ theme }) => ({
  backgroundColor: "rgba(224, 224, 227, 0.2)",
  color: "black",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  height: "125%",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: "rgba(224, 224, 227, 1)",
  },
}));

function TabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

function ProjectPage() {
  const [anecdoteOpen, setAnecdoteOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [openVolunteerDialog, setOpenVolunteerDialog] = useState(false);
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false);
  const [openLiDARDialog, setOpenLiDARDialog] = useState(false);
  const [openDroneDialog, setOpenDroneDialog] = useState(false);
  const [openSatelliteDialog, setOpenSatelliteDialog] = useState(false);
  const [openGroundSampleDialog, setOpenGroundSampleDialog] = useState(false);

  const handleAnecdoteClose = () => {
    setAnecdoteOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleVolunteerOpen = () => {
    setOpenVolunteerDialog(true);
  };

  const handleVolunteerClose = () => {
    setOpenVolunteerDialog(false);
  };

  const handleVolunteerSubmit = (volunteerData) => {
    console.log("Volunteer data:", volunteerData);
    handleVolunteerClose();
    setOpenSuccessSnackbar(true);
  };

  const handleSuccessSnackbarClose = () => {
    setOpenSuccessSnackbar(false);
  };

  const handleLiDAROpen = () => {
    setOpenLiDARDialog(true);
  };

  const handleLiDARClose = () => {
    setOpenLiDARDialog(false);
  };

  const handleDroneOpen = () => {
    setOpenDroneDialog(true);
  };

  const handleDroneClose = () => {
    setOpenDroneDialog(false);
  };

  const handleSatelliteOpen = () => {
    setOpenSatelliteDialog(true);
  };

  const handleSatelliteClose = () => {
    setOpenSatelliteDialog(false);
  };

  const handleGroundSampleOpen = () => {
    setOpenGroundSampleDialog(true);
  };

  const handleGroundSampleClose = () => {
    setOpenGroundSampleDialog(false);
  };

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      <MKBox component="main" py={12}>
        <Container maxWidth={false}>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12}>
              <MKBox
                sx={{
                  backgroundImage: `url(${fotosaereas03})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "0.75rem",
                  height: "800px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  padding: "3rem",
                }}
              >
                <MKTypography variant="h1" fontWeight="bold" color="white" mb={1}>
                  Las Huacas
                </MKTypography>
                <MKBox display="flex" alignItems="center">
                  <MKTypography variant="h5" color="white" mr={1}>
                    Liberia, 29°C
                  </MKTypography>
                  <WbSunnyIcon style={{ color: "white" }} />
                </MKBox>
              </MKBox>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MKBox p={3}>
                  <MKTypography variant="h3" mb={2}>
                    Las Huacas Restoration Project
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={2}>
                    Las Huacas, a 400-hectare farm in Costa Rica, has been undergoing a remarkable
                    transformation since the 1980s. Once a barren cattle ranch, it has blossomed
                    into a thriving ecosystem through decades of dedicated reforestation efforts.
                    Under the stewardship of Rodrigo Oreamuno Blanco, Las Huacas is at the beginning
                    of its restoration journey. The land, eroded from cattle grazing and affected by
                    droughts, presents a challenge that will require dedication and time to
                    overcome.
                  </MKTypography>
                  <MKButton variant="outlined" color="info" onClick={() => setAnecdoteOpen(true)}>
                    Read Rodrigo&apos;s Story
                  </MKButton>
                  <MKBox component="section" bgColor="white" py={1}>
                    <Container>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <VerificationButton onClick={handleDroneOpen}>
                                <MKTypography variant="h6" align="left" mb={1}>
                                  Drone Verification
                                </MKTypography>
                                <MKBox width="100%" position="relative">
                                  <MKProgress color="info" value={75} />
                                  <MKBox
                                    position="absolute"
                                    top={-50}
                                    right={-15}
                                    p={1}
                                    sx={{
                                      backgroundColor: "success.main",
                                      opacity: 0.8,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <MKTypography variant="caption" color="white">
                                      {Math.floor((1 - 75 / 100) * 300) + 1} trees needed this year
                                    </MKTypography>
                                  </MKBox>
                                </MKBox>
                              </VerificationButton>
                            </Grid>
                            <Grid item xs={12}>
                              <VerificationButton onClick={handleLiDAROpen}>
                                <MKTypography variant="h6" align="left" mb={1}>
                                  LiDAR Verification
                                </MKTypography>
                                <MKBox width="100%" position="relative">
                                  <MKProgress color="success" value={60} />
                                  <MKBox
                                    position="absolute"
                                    top={-50}
                                    right={-15}
                                    p={1}
                                    sx={{
                                      backgroundColor: "success.main",
                                      opacity: 0.8,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <MKTypography variant="caption" color="white">
                                      {Math.floor((1 - 60 / 100) * 300) + 1} trees needed this year
                                    </MKTypography>
                                  </MKBox>
                                </MKBox>
                              </VerificationButton>
                            </Grid>
                            <Grid item xs={12}>
                              <VerificationButton onClick={handleSatelliteOpen}>
                                <MKTypography variant="h6" align="left" mb={1}>
                                  Satellite Verification
                                </MKTypography>
                                <MKBox width="100%" position="relative">
                                  <MKProgress color="warning" value={90} />
                                  <MKBox
                                    position="absolute"
                                    top={-50}
                                    right={-15}
                                    p={1}
                                    sx={{
                                      backgroundColor: "success.main",
                                      opacity: 0.8,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <MKTypography variant="caption" color="white">
                                      {Math.floor((1 - 90 / 100) * 300) + 1} trees needed this year
                                    </MKTypography>
                                  </MKBox>
                                </MKBox>
                              </VerificationButton>
                            </Grid>
                            <Grid item xs={12}>
                              <VerificationButton onClick={handleGroundSampleOpen}>
                                <MKTypography variant="h6" align="left" mb={1}>
                                  Ground Sample Verification
                                </MKTypography>
                                <MKBox width="100%" position="relative">
                                  <MKProgress color="error" value={45} />
                                  <MKBox
                                    position="absolute"
                                    top={-50}
                                    right={-15}
                                    p={1}
                                    sx={{
                                      backgroundColor: "success.main",
                                      opacity: 0.8,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <MKTypography variant="caption" color="white">
                                      {Math.floor((1 - 45 / 100) * 300) + 1} trees needed this year
                                    </MKTypography>
                                  </MKBox>
                                </MKBox>
                              </VerificationButton>
                            </Grid>
                            <Grid item xs={12}>
                              <MKButton
                                variant="gradient"
                                color="success"
                                fullWidth
                                onClick={handleVolunteerOpen}
                              >
                                Volunteer at Las Huacas
                              </MKButton>
                              <MKTypography variant="body2" color="text" mt={2}>
                                Come join us on a short term - long term residency to learn about
                                agroforestry and permaculture with us at La Hacienda Las Huacas
                              </MKTypography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DynamicCheckoutCard />
                          <MKBox mt={2} width="400px" height="200px" borderRadius={10}>
                            <video
                              autoPlay
                              muted
                              onEnded={(e) => e.target.pause()}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "inherit",
                              }}
                            >
                              <source src={LasHuacasZoom} type="video/mp4" />
                            </video>
                          </MKBox>
                        </Grid>
                      </Grid>
                    </Container>
                  </MKBox>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={0} px={0} mt={0} mb={2}>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Card>
                <MKBox p={3}>
                  <MKTypography variant="h4" mb={2}>
                    Trees Planted
                  </MKTypography>
                  <MKTypography variant="h2" color="success">
                    5,000
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <MKBox p={3}>
                  <MKTypography variant="h4" mb={2}>
                    CO2 Tons Sequestered
                  </MKTypography>
                  <MKTypography variant="h2" color="success">
                    4,025
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <MKBox p={3}>
                  <MKTypography variant="h4" mb={2}>
                    Hectares Reforested
                  </MKTypography>
                  <MKTypography variant="h2" color="success">
                    15
                  </MKTypography>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={1} px={1} mt={1}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Tabs value={activeTab} onChange={handleTabChange} aria-label="project tabs">
                <Tab label="Monitoring" />
                <Tab label="Wildlife" />
                <Tab label="People" />
              </Tabs>
              <TabPanel value={activeTab} index={0}>
                <MKTypography variant="h3" mb={2}>
                  Monitored from space
                </MKTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Card>
                      <MKBox p={3} position="relative">
                        <MKBox
                          sx={{
                            position: "relative",
                            paddingTop: "56.25%", // 16:9 aspect ratio
                            overflow: "hidden",
                          }}
                        >
                          <iframe
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            src="https://www.youtube.com/embed/dGy2rcN1Et8?autoplay=1&mute=1&loop=1&playlist=dGy2rcN1Et8"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </MKBox>
                        <MKBox position="absolute" top={0} left={0} p={15} color="#ffffff">
                          <MKTypography variant="h1" fontWeight="bold" color="white">
                            Las Huacas is LIDAR Monitored
                          </MKTypography>
                          <MKTypography variant="h5" color="white">
                            This means we can easily understand and detect changes that happen to
                            Las Huacas
                          </MKTypography>
                        </MKBox>
                      </MKBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <MKBox p={3}>
                        <MKTypography variant="h3" mb={2}>
                          Predict the future
                        </MKTypography>
                        <MKTypography variant="h5">
                          Using satellite imagery and AI, we have the ability to monitor the health
                          of Las Huacas.
                        </MKTypography>
                        <MKBox mt={2}>
                          <video
                            width="100%"
                            height="auto"
                            autoPlay
                            loop
                            muted
                            style={{
                              objectFit: "cover",
                              borderRadius: "0.75rem",
                            }}
                          >
                            <source src={lasHuacasGIF} type="video/mp4" />
                          </video>
                        </MKBox>
                      </MKBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKBox
                      component="img"
                      src={mapasForestCover}
                      alt="Forest Cover Map"
                      width="100%"
                      height="auto"
                      borderRadius="0.75rem"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={2}>
                      {[
                        { src: multitemporal20170425, alt: "20170425" },
                        { src: multitemporal20171229, alt: "20171229" },
                        { src: multitemporal20190418, alt: "20190418" },
                        { src: multitemporal20191227, alt: "20191227" },
                        { src: multitemporal20240426, alt: "20240426" },
                        { src: multitemporalApril171924, alt: "MultitemporalApril17,19,24" },
                      ].map(({ src, alt }, index) => (
                        <Grid item xs={6} key={index}>
                          <MKBox position="relative">
                            <MKBox component="img" src={src} alt={alt} width="100%" height="auto" />
                            <MKBox position="absolute" top={0} right={0} p={1} zIndex={1}>
                              <MKTypography variant="h6" color="white">
                                {alt}
                              </MKTypography>
                            </MKBox>
                          </MKBox>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Card>
                  <MKBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <MKTypography variant="h2" fontWeight="bold" mb={2}>
                          Cameras that bring you closer to Las Huacas
                        </MKTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <MKBox
                          sx={{
                            position: "relative",
                            paddingTop: "56.25%", // 16:9 aspect ratio
                            overflow: "hidden",
                          }}
                        >
                          <iframe
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            src="https://www.youtube.com/embed/F0GOOP82094?autoplay=1&mute=1"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </MKBox>
                      </Grid>
                    </Grid>
                  </MKBox>
                </Card>
                <MKTypography variant="h3" mb={2}>
                  Habitants of Las Huacas
                </MKTypography>
                <Grid container spacing={2}>
                  {[
                    { src: faunaInsect, alt: "insect" },
                    { src: faunaMono, alt: "mono" },
                    { src: faunaPziote, alt: "pziote" },
                    { src: faunaSloth, alt: "sloth" },
                    { src: faunaTigrillo, alt: "tigrillo" },
                    { src: faunaZorro, alt: "zorro" },
                  ].map(({ src, alt }, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <MKBox
                        component="img"
                        src={src}
                        alt={alt}
                        width="100%"
                        height={{ xs: "200px", sm: "250px", md: "350px" }}
                        borderRadius="0.75rem"
                        style={{ cursor: "pointer", objectFit: "cover" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <MKTypography variant="h3" mb={2}>
                  People who run Las Huacas
                </MKTypography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKBox
                      component="img"
                      src={reforestacionCIMG1484}
                      alt="CIMG1484"
                      width="100%"
                      height="auto"
                      borderRadius="0.75rem"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKBox
                      component="img"
                      src={reforestacionCIMG1477}
                      alt="CIMG1477"
                      width="100%"
                      height="auto"
                      borderRadius="0.75rem"
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox pt={1} px={1} mt={1}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Card>
                <MKBox p={3}>
                  <MKTypography variant="h3" mb={2}>
                    Project Documents
                  </MKTypography>
                  <Grid container spacing={2}>
                    {[
                      { label: "Document1.pdf" },
                      { label: "Document2.pdf" },
                      { label: "Document3.pdf" },
                    ].map(({ label }, index) => (
                      <Grid item xs={12} key={index}>
                        <MKBox
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          mb={1}
                        >
                          <MKBox display="flex" alignItems="center">
                            <PictureAsPdfIcon style={{ color: "green", marginRight: "8px" }} />
                            <MKTypography variant="body1" style={{ cursor: "pointer" }}>
                              {label}
                            </MKTypography>
                          </MKBox>
                          <DownloadIcon style={{ cursor: "pointer" }} />
                        </MKBox>
                      </Grid>
                    ))}
                  </Grid>
                </MKBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Dialog open={anecdoteOpen} onClose={handleAnecdoteClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <MKTypography variant="h4" component="div" fontWeight="bold">
            The Story of Las Huacas by Rodrigo Oreamuno Blanco
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKTypography variant="body1">
            Around 1980, two American friends and I acquired Las Huacas. A few years later, I bought
            out their share and became the sole owner of the farm, which at that time measured
            approximately 200 hectares. Over the following years, I purchased several contiguous
            plots of land. The largest acquisition was a property of 100 hectares; I also bought
            smaller pieces. As a result, Las Huacas and Arvida S.A. (another company I owned which
            had land next to Las Huacas, where the house we lived in for a long time was built)
            ended up measuring approximately 400 hectares together.
            <br />
            <br />
            The previous owner of Las Huacas was an American; so were its administrators. Their
            viewpoint (which I neither shared nor share at all) was that a good cattle rancher has
            good pastures. Trees take up space from pastures and, therefore, only the essentials
            should be kept, so the cattle have shade. For this reason, there were practically no
            trees in Las Huacas.
            <br />
            <br />
            Since acquiring the farm, I set out to reforest it. As expected from a lawyer completely
            ignorant of cattle ranching and arboriculture, I made many mistakes. Perhaps the main
            one was planting some species (Eucalyptus, for example) foreign to the area, which did
            not thrive well. One thing I did get right from the start was understanding that nature
            was the best reforester and that it was necessary to create the conditions for her to
            carry out her work.
            <br />
            <br />
            In addition to nature&apos;s reforestation efforts, we did our part. Over about 40
            years, we planted trees and shrubs of many species, to name a few: Guanacaste, Higuerón,
            Pochote, Ron Ron, Espavel, Cocobolo, Guarumo, Almendro de Monte, Mahogany, Bitter Cedar,
            Madero Negro, Royal Guayacán, Yellow Cortez, Purple Cortez, Ceibo, Malinche, Jacaranda,
            Poro Poro, Cassia Fistula, Savannah Oak, Vainillo, Guachipelín, Gallinazo, Cenízaro,
            Carao, Sotacaballo, Ipíl Ipíl, Sen Sen, Teak, Gmelina, Caribbean Pine, Eucalyptus,
            Lorito, Guaria de Palo, and Indian Laurel. We also planted several other ornamental
            plants and trees that escape my memory. Moreover, we grew many fruit trees, most for
            family consumption and some for sale. I remember the following: pink grapefruit, sour
            lemons (Messina and others), tangerines, oranges, soursop, various kinds of mangoes,
            etc. Zaida made some attempts to grow a vegetable garden, which were not successful.
            <br />
            <br />
            Since the late 20th century, we have provided modest scholarships to some children of
            the farm workers and to other underprivileged youngsters living near there.
            <br />
            <br />
            About 20 years ago, we started a reforestation program that consisted of the following:
            every first Saturday in June (in that area, it starts raining in mid-May), the
            scholarship students, my wife Zaida, my children, my grandchildren, and I had to plant
            1,000 trees. We got the saplings in advance and transported them to where we would plant
            them. There, the farmhands had dug the holes where we would plant the trees in the
            previous days. Our task began at 6:00 a.m. We planted each sapling, fertilized it, and
            refilled the hole with the soil that had been dug out. The process was beautiful, not
            only from a forestry perspective but also because it allowed interaction between city
            children and local youths and adults! We repeated this process for 5 years and planted
            about 5,000 trees this way. I decided to suspend it because it would leave the farm
            without pastures for
            <br />
            <br />
            Besides the work of nature and our activity every first Saturday in June, Las Huacas,
            through its employees, also carried out tree plantings in various sectors of the farm.
            <br />
            <br />
            Although Las Huacas is fundamentally flat, there are a few hills that became a problem
            due to the difficulty of eliminating the weeds that grew on them. After trying several
            solutions, we opted for the simplest: reforest the hills. To do this, we began by
            allowing the trees that naturally sprouted there to grow. Once those hills were filled
            with young trees, we contributed to the process by planting many additional trees,
            enriching the new forests that were forming. As a result, over the years, several forest
            masses and biological corridors formed on the hills.
            <br />
            <br />
            We sold the wood from some trees we planted and earned a modest income. However, the
            most important thing is that many of the lands of Las Huacas, which had almost no trees,
            are now beautiful forests.
            <br />- Rodrigo Oreamuno Blanco
          </MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleAnecdoteClose}>Close</MKButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openVolunteerDialog} onClose={handleVolunteerClose}>
        <DialogTitle>Volunteer Information</DialogTitle>
        <DialogContent>
          <VolunteerForm onSubmit={handleVolunteerSubmit} onCancel={handleVolunteerClose} />
        </DialogContent>
      </Dialog>
      <Dialog open={openSuccessSnackbar} onClose={handleSuccessSnackbarClose}>
        <DialogTitle>Good job!</DialogTitle>
        <DialogContent>
          <MKTypography>You have successfully submitted your volunteer information!</MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleSuccessSnackbarClose} color="success">
            Close
          </MKButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openLiDARDialog} onClose={handleLiDARClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <MKTypography variant="h4" component="div" fontWeight="bold">
            LiDAR Verification
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKBox
            component="img"
            src={LiDARdiagram}
            alt="LiDAR Diagram"
            width="100%"
            borderRadius="0.75rem"
          />
          <MKTypography variant="body1" mt={2}>
            LiDAR (Light Detection and Ranging) technology uses laser pulses to create precise 3D
            maps of the project area, allowing for accurate monitoring of tree growth and forest
            density.
          </MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleLiDARClose}>Close</MKButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openDroneDialog} onClose={handleDroneClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <MKTypography variant="h4" component="div" fontWeight="bold">
            Drone Verification
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKBox
            component="img"
            src={Dronediagram}
            alt="Drone Diagram"
            width="100%"
            borderRadius="0.75rem"
          />
          <MKTypography variant="body1" mt={2}>
            Drone technology enables frequent aerial surveys of the project area, providing
            high-resolution imagery for detailed monitoring of reforestation progress and overall
            ecosystem health.
          </MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleDroneClose}>Close</MKButton>
        </DialogActions>
      </Dialog>
      <Dialog open={openSatelliteDialog} onClose={handleSatelliteClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <MKTypography variant="h4" component="div" fontWeight="bold">
            Satellite Verification
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKBox
            component="img"
            src={SatelliteDiagram}
            alt="Satellite Diagram"
            width="100%"
            borderRadius="0.75rem"
          />
          <MKTypography variant="body1" mt={2}>
            Satellite technology provides regular, wide-area coverage of the project site, allowing
            for continuous monitoring of large-scale changes and overall forest health.
          </MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleSatelliteClose}>Close</MKButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openGroundSampleDialog}
        onClose={handleGroundSampleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <MKTypography variant="h4" component="div" fontWeight="bold">
            Ground Sample Verification
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKBox
            component="img"
            src={GroundSampleDiagram}
            alt="Ground Sample Diagram"
            width="100%"
            borderRadius="0.75rem"
          />
          <MKTypography variant="body1" mt={2}>
            Ground sample verification involves on-site data collection and analysis, providing
            detailed, up-close information about soil health, biodiversity, and individual tree
            growth.
          </MKTypography>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleGroundSampleClose}>Close</MKButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProjectPage;
