import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db } from "firebaseConfig";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

function SignUpCoverPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const guid = searchParams.get("guid");
  const prefilledEmail = searchParams.get("email");

  useEffect(() => {
    if (prefilledEmail) {
      setEmail(prefilledEmail);
    }
  }, [prefilledEmail]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: name });

      if (guid) {
        const pendingAccountRef = doc(db, "pendingAccounts", guid);
        const pendingAccountSnap = await getDoc(pendingAccountRef);

        if (pendingAccountSnap.exists()) {
          const pendingAccountData = pendingAccountSnap.data();
          await setDoc(doc(db, "userAccounts", user.uid), {
            ...pendingAccountData,
            name: name,
            uid: user.uid,
          });
          await deleteDoc(pendingAccountRef);
        }
      } else {
        // If there's no guid, create a new user account without pending data
        await setDoc(doc(db, "userAccounts", user.uid), {
          name: name,
          email: email,
          uid: user.uid,
          createdAt: new Date(),
          treeQuantity: 0,
          co2Offset: 0,
          totalSpent: 0,
          purchases: [],
        });
      }

      navigate("/pages/lashuacas-dashboard");
    } catch (error) {
      console.error("Error during sign up:", error);
      setError(error.message);
    }
  };

  return (
    <MKBox
      component="form"
      role="form"
      onSubmit={handleSignUp}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "400px",
        margin: "auto",
        padding: "2rem",
      }}
    >
      <MKTypography variant="h4" mb={2}>
        Sign Up
      </MKTypography>
      <MKInput
        type="text"
        label="Name"
        fullWidth
        value={name}
        onChange={(e) => setName(e.target.value)}
        sx={{ mb: 2 }}
        required
      />
      <MKInput
        type="email"
        label="Email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        sx={{ mb: 2 }}
        required
      />
      <MKInput
        type="password"
        label="Password"
        fullWidth
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        sx={{ mb: 2 }}
        required
      />
      {error && (
        <MKTypography variant="body2" color="error" mb={2}>
          {error}
        </MKTypography>
      )}
      <MKButton variant="gradient" color="info" fullWidth type="submit">
        Sign Up
      </MKButton>
    </MKBox>
  );
}

export default SignUpCoverPage;
