import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

function VolunteerForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const { currentUser } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser) {
      // Redirect to login or show login modal
      return;
    }

    try {
      const idToken = await currentUser.getIdToken();
      const response = await fetch("/api/volunteer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ name, email, phone }),
      });

      if (response.ok) {
        // Handle successful submission
        alert("Volunteer application submitted successfully!");
        setName("");
        setEmail("");
        setPhone("");
      } else {
        throw new Error("Failed to submit volunteer application");
      }
    } catch (error) {
      console.error("Error submitting volunteer application:", error);
      alert("Failed to submit volunteer application. Please try again.");
    }
  };

  return (
    <MKBox component="form" onSubmit={handleSubmit}>
      <MKTypography variant="h4" mb={2}>
        Volunteer Application
      </MKTypography>
      <MKBox mb={2}>
        <MKInput
          type="text"
          label="Full Name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </MKBox>
      <MKBox mb={2}>
        <MKInput
          type="email"
          label="Email Address"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </MKBox>
      <MKBox mb={2}>
        <MKInput
          type="tel"
          label="Phone Number"
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </MKBox>
      <MKButton type="submit" variant="gradient" color="info" fullWidth>
        Submit Application
      </MKButton>
    </MKBox>
  );
}

export default VolunteerForm;
