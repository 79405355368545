import React from "react";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleBackgroundCard from "examples/Cards/BackgroundCards/SimpleBackgroundCard";
import routes from "routes";
import lasHuacasImage from "assets/images/LasHuacas.png";
import limonImage from "assets/images/limon.png";
import pavonesImage from "assets/images/pavones.png";

function CarbonDashboard() {
  const navigate = useNavigate();
  const projects = [
    {
      image: lasHuacasImage,
      title: "Las Huacas",
      description:
        "Reforestation Hacienda Las Huacas transformative environmental project that " +
        "transitions 400 hectares of pastureland in Costa Rica into thriving forests.",
      route: "/pages/presentation/las-huacas",
      available: true,
    },
    {
      image: limonImage,
      title: "Limon",
      description: "Reforestation besides Pineapple plantation",
      route: "/somewhere",
      available: false,
    },
    {
      image: pavonesImage,
      title: "Pavones",
      description: "Ecosystemic conservation besides one of the largest waves in the world",
      route: "/somewhere",
      available: false,
    },
  ];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      <MKBox py={3} px={1}>
        <MKTypography variant="h2" mb={1} pl={9}>
          Projects
        </MKTypography>
        <MKTypography variant="h5" mb={3} pl={9}>
          A list of curated projects that believe in positive change
        </MKTypography>
        <Grid container spacing={3} justifyContent="center">
          {projects.map((project, index) => (
            <Grid item xs={11} key={index}>
              <SimpleBackgroundCard
                image={project.image}
                title={project.title}
                description={project.description}
                onClick={() => project.available && navigate(project.route)}
                sx={{ cursor: project.available ? "pointer" : "default", position: "relative" }}
              >
                {!project.available && (
                  <MKBox
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "rgba(128, 128, 128, 0.2)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MKTypography variant="h4" color="white">
                      Available soon...
                    </MKTypography>
                  </MKBox>
                )}
              </SimpleBackgroundCard>
            </Grid>
          ))}
        </Grid>
      </MKBox>
    </>
  );
}

export default CarbonDashboard;
