import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  Card,
  CardContent,
  Stack,
  useMediaQuery,
  Slider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

const countryAverages = {
  Australia: 15.22,
  Austria: 7.13,
  Belarus: 6.13,
  Belgium: 8.34,
  Bulgaria: 5.62,
  Canada: 15.32,
  Croatia: 4.62,
  Cyprus: 5.55,
  "Czech Republic": 9.13,
  Denmark: 5.65,
  Estonia: 11.3,
  Finland: 7.91,
  France: 4.81,
  Germany: 8.56,
  Greece: 5.78,
  Hungary: 5.38,
  Iceland: 9.46,
  Ireland: 7.38,
  Italy: 5.38,
  Japan: 8.39,
  Latvia: 3.93,
  Liechtenstein: 3.69,
  Lithuania: 5.09,
  Luxembourg: 15.3,
  Malta: 3.62,
  Monaco: 1.29,
  Netherlands: 8.81,
  "New Zealand": 6.85,
  Norway: 7.03,
  Poland: 8.24,
  Portugal: 4.33,
  Romania: 3.85,
  "Russian Federation": 11.64,
  Slovakia: 5.94,
  Slovenia: 6.21,
  Spain: 5.03,
  Sweden: 3.83,
  Switzerland: 4.18,
  Turkey: 5.02,
  Ukraine: 4.95,
  "United Kingdom": 5.55,
  "United States": 15.52,
};

const CarbonFootprintCalculator = () => {
  const navigate = useNavigate();
  const [stage, setStage] = useState("selectLocation");
  const [country, setCountry] = useState("Netherlands");
  const [bedrooms, setBedrooms] = useState(2);
  const [occupants, setOccupants] = useState(2);
  const [transportationMode, setTransportationMode] = useState("");
  const [dietType, setDietType] = useState("");
  const [co2Footprint, setCo2Footprint] = useState(0);
  const [previousFootprint, setPreviousFootprint] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const isDesktop = useMediaQuery("(min-width:1024px)");
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:1023px)");

  useEffect(() => {
    calculateCo2Footprint();
  }, [country, bedrooms, occupants, transportationMode, dietType]);

  const calculateCo2Footprint = () => {
    let footprint = countryAverages[country] || 4.72;
    const estimatedEnergyUsage = (bedrooms * 1.5 + occupants * 0.5) * 1000;
    footprint += estimatedEnergyUsage * 0.0005;

    switch (transportationMode) {
      case "Car":
        footprint += 4.6;
        break;
      case "Public Transportation":
        footprint += 1.8;
        break;
      case "Bicycle":
        footprint += 0.2;
        break;
      case "Walking":
        footprint += 0;
        break;
      default:
        footprint += 2;
    }

    switch (dietType) {
      case "Meat-based":
        footprint += 3.3;
        break;
      case "Vegetarian":
        footprint += 1.7;
        break;
      case "Vegan":
        footprint += 1.5;
        break;
      default:
        footprint += 2.5;
    }

    setPreviousFootprint(co2Footprint);
    setCo2Footprint(footprint);
  };

  const handleNext = (nextStage) => {
    setStage(nextStage);
  };

  const handleFinalize = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/dashboards/default");
  };

  const treesToOffset = Math.ceil(co2Footprint / 2);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        width: "100vw",
        background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
      }}
    >
      <Card
        sx={{
          width: isDesktop ? "40%" : isTablet ? "60%" : "90%",
          padding: 4,
          boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
        }}
      >
        {stage === "selectLocation" && (
          <Box>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
              Calculate your footprint
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }}>
              Where do you live? 🌍
            </Typography>
            <Select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
              sx={{ mb: 4 }}
            >
              {Object.keys(countryAverages).map((c) => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext("energyUsage")}
              fullWidth
            >
              Next
            </Button>
          </Box>
        )}

        {stage === "energyUsage" && (
          <Box>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
              Household Information 🏠
            </Typography>
            <TextField
              label="Number of Bedrooms"
              type="number"
              value={bedrooms}
              onChange={(e) => setBedrooms(Number(e.target.value))}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Number of Occupants"
              type="number"
              value={occupants}
              onChange={(e) => setOccupants(Number(e.target.value))}
              fullWidth
              sx={{ mb: 4 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext("transportation")}
              fullWidth
            >
              Next
            </Button>
          </Box>
        )}

        {stage === "transportation" && (
          <Box>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
              Transportation 🚗
            </Typography>
            <RadioGroup
              value={transportationMode}
              onChange={(e) => setTransportationMode(e.target.value)}
            >
              <FormControlLabel value="Car" control={<Radio />} label="Car 🚙" />
              <FormControlLabel
                value="Public Transportation"
                control={<Radio />}
                label="Public Transportation 🚌"
              />
              <FormControlLabel value="Bicycle" control={<Radio />} label="Bicycle 🚲" />
              <FormControlLabel value="Walking" control={<Radio />} label="Walking 🚶‍♂️" />
            </RadioGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext("diet")}
              fullWidth
              sx={{ mt: 4 }}
            >
              Next
            </Button>
          </Box>
        )}

        {stage === "diet" && (
          <Box>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
              Diet 🍏
            </Typography>
            <RadioGroup value={dietType} onChange={(e) => setDietType(e.target.value)}>
              <FormControlLabel value="Meat-based" control={<Radio />} label="Meat-based 🍖" />
              <FormControlLabel value="Vegetarian" control={<Radio />} label="Vegetarian 🥦" />
              <FormControlLabel value="Vegan" control={<Radio />} label="Vegan 🌱" />
            </RadioGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleNext("viewProfile")}
              fullWidth
              sx={{ mt: 4 }}
            >
              Next
            </Button>
          </Box>
        )}

        {stage === "viewProfile" && (
          <Box>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 4 }}>
              Carbon Profile 📘
            </Typography>
            <DefaultCounterCard
              count={co2Footprint.toFixed(2)}
              suffix="tons/year"
              title="Your Carbon Footprint"
              description="Estimated annual CO2 emissions based on your inputs"
            />
            <Slider
              value={co2Footprint}
              min={0}
              max={Math.max(countryAverages[country] * 2, co2Footprint)}
              marks={[
                { value: 0, label: "0" },
                {
                  value: countryAverages[country],
                  label: `Avg (${countryAverages[country]})`,
                },
                {
                  value: co2Footprint,
                  label: `You (${co2Footprint.toFixed(2)})`,
                },
              ]}
              valueLabelDisplay="auto"
              sx={{ mb: 4 }}
            />
            <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 4 }}>
              <Box sx={{ width: "50%" }}>
                <video width="100%" height="auto" src="/OakScan.mp4" autoPlay muted loop />
              </Box>
              <Box sx={{ width: "50%" }}>
                <Typography variant="body1">
                  To offset your carbon footprint, you would need to plant approximately{" "}
                  {treesToOffset.toLocaleString()} trees.
                </Typography>
              </Box>
            </Stack>
            <Button variant="contained" color="primary" onClick={handleFinalize} fullWidth>
              Finalize
            </Button>
          </Box>
        )}
      </Card>

      {stage !== "selectLocation" && (
        <Card
          sx={{
            position: "absolute",
            top: "20%",
            right: "30%",
            padding: 2,
            boxShadow: "0 4px 20px 0 rgba(0,0,0,0.1)",
          }}
        >
          <CardContent>
            <Typography variant="h6" sx={{ mb: 1 }}>
              CO2 Footprint
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h4">{co2Footprint.toFixed(2)}</Typography>
              <Typography variant="body1">tons/year</Typography>
              {co2Footprint > previousFootprint ? (
                <KeyboardDoubleArrowUpIcon color="error" />
              ) : (
                <KeyboardDoubleArrowDownIcon color="success" />
              )}
            </Stack>
          </CardContent>
        </Card>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Good job!</DialogTitle>
        <DialogContent>
          <Typography>You have successfully calculated your carbon footprint!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            View Dashboard
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CarbonFootprintCalculator;
