// src/pages/Authentication/SignOut/index.js

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { useAuth } from "contexts/AuthContext";

function SignOut() {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  useEffect(() => {
    const handleSignOut = async () => {
      try {
        if (typeof signOut !== "function") {
          console.error("signOut is not a function:", signOut);
          return;
        }
        await signOut();
        navigate("/");
      } catch (error) {
        console.error("Error signing out:", error);
      }
    };

    handleSignOut();
  }, [signOut, navigate]);

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <MKTypography variant="h4">Signing out...</MKTypography>
    </MKBox>
  );
}

export default SignOut;
