import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function CheckoutSuccess() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");
    if (sessionId) {
      fetch(`${process.env.REACT_APP_API_URL}/checkout-success?session_id=${sessionId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            setCustomerData(data.customerData);
            setTimeout(() => {
              navigate(data.redirect);
            }, 5000); // Redirect after 5 seconds
          } else {
            setError(data.error || "An unexpected error occurred.");
          }
        })
        .catch((err) => {
          console.error("Error:", err);
          setError("An unexpected error occurred.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError("No session ID provided.");
      setLoading(false);
    }
  }, [location, navigate]);

  if (loading) {
    return (
      <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <MKTypography variant="h4">Processing your payment...</MKTypography>
      </MKBox>
    );
  }

  if (error) {
    return (
      <MKBox display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <MKTypography variant="h4" color="error">
          Error: {error}
        </MKTypography>
      </MKBox>
    );
  }

  if (customerData) {
    return (
      <MKBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <MKTypography variant="h4">Thank you for your purchase!</MKTypography>
        <MKTypography variant="body1">
          You have planted {customerData.treeQuantity} trees.
        </MKTypography>
        <MKTypography variant="body1">
          You have offset {customerData.co2Offset}kg of CO2.
        </MKTypography>
        <MKTypography variant="body2">Redirecting to dashboard in 5 seconds...</MKTypography>
      </MKBox>
    );
  }

  return null;
}

export default CheckoutSuccess;
