import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";
import routes from "routes";
import { AuthProvider } from "./contexts/AuthContext";
import SignUpCoverPage from "./pages/Authentication/SignUp/Cover";
import LasHuacasDashboard from "./pages/referral/LasHuacasDashboard";

// Firebase imports
import { auth, db } from "./firebaseConfig";

export default function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          {getRoutes(routes)}
          <Route path="/presentation" element={<Presentation />} />
          <Route path="/authentication/sign-up/cover" element={<SignUpCoverPage />} />
          <Route path="/pages/lashuacas-dashboard" element={<LasHuacasDashboard />} />
          <Route path="*" element={<Navigate to="/presentation" />} />
        </Routes>
      </ThemeProvider>
    </AuthProvider>
  );
}

// Export Firebase instances if needed in other parts of your app
export { db, auth };
