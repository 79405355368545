import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { db } from "../firebaseConfig"; // Assuming you are importing from the correct file
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const stripePromise = loadStripe(
  "pk_live_51OAZLXIFktvR7sI0vhSyqJktnlrsAmgWV8jPzXTpKXTHeCkeETvTjHL8Y9fW7eO7VBeQltBRmRMAPVwgNp4fSb7600Msxti9zY"
);

function DynamicCheckoutCard() {
  const [treeCount, setTreeCount] = useState(15);
  const [price, setPrice] = useState(210);
  const [co2Offset, setCo2Offset] = useState(60);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleIncrement = () => {
    const newTreeCount = treeCount + 1;
    setTreeCount(newTreeCount);
    setPrice(newTreeCount * 14);
    setCo2Offset(newTreeCount * 4);
  };

  const handleDecrement = () => {
    const newTreeCount = Math.max(1, treeCount - 1);
    setTreeCount(newTreeCount);
    setPrice(newTreeCount * 14);
    setCo2Offset(newTreeCount * 4);
  };

  const handleCheckout = async () => {
    setLoading(true);
    setError(null);
    try {
      const stripe = await stripePromise;
      const response = await fetch("/api/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ quantity: treeCount }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create checkout session");
      }

      const session = await response.json();
      if (!session || !session.id) {
        throw new Error("Invalid response from server");
      }

      // Add checkout data to Firestore
      try {
        const docRef = await addDoc(collection(db, "checkouts"), {
          sessionId: session.id,
          treeCount: treeCount,
          price: price,
          co2Offset: co2Offset,
          createdAt: serverTimestamp(),
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (firestoreError) {
        console.error("Error adding document to Firestore: ", firestoreError);
        // Log the error but don't throw it, allow the checkout to continue
      }

      const result = await stripe.redirectToCheckout({ sessionId: session.id });
      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Checkout error:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MKBox p={3}>
      <MKTypography variant="h6" gutterBottom>
        Plant Trees
      </MKTypography>
      <MKBox display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <MKButton color="secondary" onClick={handleDecrement} disabled={loading}>
          <RemoveIcon />
        </MKButton>
        <MKTypography variant="h5">{treeCount} trees</MKTypography>
        <MKButton color="secondary" onClick={handleIncrement} disabled={loading}>
          <AddIcon />
        </MKButton>
      </MKBox>
      <MKTypography variant="body2" color="text" mb={1}>
        Current value: ${(price / treeCount).toFixed(2)} / tree
      </MKTypography>
      <MKTypography variant="body2" color="text" mb={1}>
        Total: ${price.toFixed(2)}
      </MKTypography>
      <MKTypography variant="body2" color="success" mb={2}>
        Offsets {co2Offset}kg of CO2
      </MKTypography>
      {error && (
        <MKTypography variant="body2" color="error" mb={2}>
          {error}
        </MKTypography>
      )}
      <MKButton
        variant="gradient"
        color="success"
        fullWidth
        onClick={handleCheckout}
        disabled={loading}
      >
        {loading ? "Processing..." : `Request to plant $${price.toFixed(2)}`}
      </MKButton>
    </MKBox>
  );
}

export default DynamicCheckoutCard;
