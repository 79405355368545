import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types"; // Changed to double quotes
import { useAuth } from "../contexts/AuthContext";

function PrivateRoute({ children }) {
  const { currentUser } = useAuth();

  return currentUser ? children : <Navigate to="/authentication/sign-in/cover" />;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
