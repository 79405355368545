import React, { useState, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Card,
  Divider,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from "@mui/material";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";
import { useNavigate } from "react-router-dom";

// Icons
import CO2Icon from "@mui/icons-material/Co2";
import SearchIcon from "@mui/icons-material/Search";
import NatureIcon from "@mui/icons-material/Nature";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CloseIcon from "@mui/icons-material/Close";

// Lazy load MapComponent
const LazyMapComponent = lazy(() => import("./MapComponent"));

function TimelineItem({ color, icon, title, description, lastItem, active }) {
  return (
    <MKBox
      position="relative"
      mb={!lastItem && 3}
      sx={{
        "&:after": {
          content: "''",
          position: "absolute",
          top: "14px",
          left: "14px",
          height: lastItem ? "0" : "calc(100% - 14px)",
          width: "2px",
          backgroundColor: active ? `${color}.main` : "grey.300",
        },
      }}
    >
      <MKBox display="flex" justifyContent="flex-start" alignItems="center" mb={1}>
        <MKBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={28}
          height={28}
          borderRadius="50%"
          bgcolor={active ? `${color}.main` : "grey.300"}
          color={active ? "white" : "text.secondary"}
          mr={2}
          fontSize="small"
          zIndex={2}
        >
          {icon}
        </MKBox>
        <MKTypography
          variant="body2"
          fontWeight="bold"
          color={active ? "text.primary" : "text.secondary"}
        >
          {title}
        </MKTypography>
      </MKBox>
      <MKBox ml={5.75} pt={description ? 0.7 : 0.5}>
        <MKTypography
          variant="body2"
          color={active ? "text.secondary" : "text.disabled"}
          fontWeight="regular"
        >
          {description}
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

TimelineItem.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
  completed: PropTypes.bool,
  active: PropTypes.bool,
};

TimelineItem.defaultProps = {
  description: "",
  lastItem: false,
  completed: false,
  active: false,
};

function OrderInformation() {
  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const navigate = useNavigate();

  const handleInvoiceOpen = () => setInvoiceOpen(true);
  const handleInvoiceClose = () => setInvoiceOpen(false);

  return (
    <MKBox component="section" py={12}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={8}>
          <Card>
            <MKBox p={3}>
              <MKTypography variant="h3" mb={3}>
                Your Tree Planting Journey
              </MKTypography>
              <MKAlert color="success" dismissible>
                <Icon fontSize="small">thumb_up</Icon>&nbsp; Your CO2 footprint is now 5 tons.
                That&apos;s 60% less than before!
              </MKAlert>
              <MKBox mt={4} display="flex">
                <MKBox width="70%">
                  <TimelineItem
                    color="info"
                    icon={<CO2Icon />}
                    title="Your footprint 🏷️"
                    description="You calculated your carbon footprint that impacts the planet"
                    completed
                    active
                  />
                  <TimelineItem
                    color="info"
                    icon={<SearchIcon />}
                    title="Browse projects 🔍"
                    description="Based on your footprint, we suggested projects for you to invest in"
                    completed
                    active
                  />
                  <TimelineItem
                    color="success"
                    icon={<NatureIcon />}
                    title="Buy trees 🌳"
                    description="You bought trees in a project you want to support and be part of"
                    completed
                    active
                  />
                  <TimelineItem
                    color="warning"
                    icon={<ReceiptIcon />}
                    title="Order Received by Las Huacas 📥"
                    description="Your order is received by Las Huacas"
                    active
                  />
                  <TimelineItem
                    color="primary"
                    icon={<LocationOnIcon />}
                    title="Receive exact location of trees 📍"
                    description="The trees you bought are planted and you receive the exact location on the map"
                  />
                  <TimelineItem
                    color="dark"
                    icon={<DashboardIcon />}
                    title="Manage your project 📊"
                    description="You are part owner of the project you invest in. You get access to an informative dashboard."
                    lastItem
                  />
                </MKBox>
                <MKBox width="30%" ml={3}>
                  <MKTypography variant="h6" mb={2}>
                    Goal Progress
                  </MKTypography>
                  <MKBox display="flex" alignItems="center" mb={2}>
                    <MKTypography variant="button" fontWeight="regular" color="text" mr={1}>
                      Drone verification
                    </MKTypography>
                    <LinearProgress
                      variant="determinate"
                      value={75}
                      sx={{
                        width: "100%",
                        height: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#e8f5e9",
                        "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" },
                      }}
                    />
                  </MKBox>
                  <MKBox display="flex" alignItems="center" mb={2}>
                    <MKTypography variant="button" fontWeight="regular" color="text" mr={1}>
                      LiDAR verification
                    </MKTypography>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      sx={{
                        width: "100%",
                        height: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#e8f5e9",
                        "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" },
                      }}
                    />
                  </MKBox>
                  <MKBox display="flex" alignItems="center">
                    <MKTypography variant="button" fontWeight="regular" color="text" mr={1}>
                      Satellite verification
                    </MKTypography>
                    <LinearProgress
                      variant="determinate"
                      value={90}
                      sx={{
                        width: "100%",
                        height: "8px",
                        borderRadius: "4px",
                        backgroundColor: "#e8f5e9",
                        "& .MuiLinearProgress-bar": { backgroundColor: "#4caf50" },
                      }}
                    />
                  </MKBox>
                </MKBox>
              </MKBox>

              <Divider sx={{ my: 4 }} />

              <MKBox display="flex" justifyContent="space-between" alignItems="center">
                <MKBox>
                  <MKTypography variant="h6" fontWeight="medium">
                    Order Details
                  </MKTypography>
                  <MKTypography variant="button" color="text">
                    Order no. <strong>241342</strong> from <strong>23.05.2023</strong>
                  </MKTypography>
                </MKBox>
                <MKButton variant="gradient" color="info" onClick={handleInvoiceOpen}>
                  View Invoice
                </MKButton>
              </MKBox>

              <MKBox mt={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <MKTypography variant="h6" mb={2}>
                      Order Summary
                    </MKTypography>
                    <MKBox display="flex" justifyContent="space-between" mb={1}>
                      <MKTypography variant="button" fontWeight="regular" color="text">
                        Trees Purchased:
                      </MKTypography>
                      <MKTypography variant="body2" fontWeight="medium">
                        15
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" justifyContent="space-between" mb={1}>
                      <MKTypography variant="button" fontWeight="regular" color="text">
                        Price per Tree:
                      </MKTypography>
                      <MKTypography variant="body2" fontWeight="medium">
                        $14
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" justifyContent="space-between" mb={1}>
                      <MKTypography variant="button" fontWeight="regular" color="text">
                        Total CO2 Offset:
                      </MKTypography>
                      <MKTypography variant="body2" fontWeight="medium">
                        60kg
                      </MKTypography>
                    </MKBox>
                    <Divider sx={{ my: 2 }} />
                    <MKBox display="flex" justifyContent="space-between">
                      <MKTypography variant="button" fontWeight="regular" color="text">
                        Total:
                      </MKTypography>
                      <MKTypography variant="body2" fontWeight="medium">
                        $210
                      </MKTypography>
                    </MKBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MKTypography variant="h6" mb={2}>
                      AI Allocation
                    </MKTypography>
                    <MKTypography variant="body2" color="text" mb={2}>
                      Our AI has allocated an approximate area where your trees will perform
                      optimally, avoiding monoculture and boosting soil regeneration.
                    </MKTypography>
                    <Suspense fallback={<MKTypography>Loading map...</MKTypography>}>
                      <LazyMapComponent />
                    </Suspense>
                  </Grid>
                </Grid>
              </MKBox>

              <MKBox mt={4} textAlign="center">
                <MKButton
                  variant="gradient"
                  color="success"
                  size="large"
                  onClick={() => navigate("/pages/lashuacas-dashboard")}
                >
                  Go to My Dashboard
                </MKButton>
              </MKBox>
            </MKBox>
          </Card>
        </Grid>
      </Grid>

      <Dialog open={invoiceOpen} onClose={handleInvoiceClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Invoice
          <Icon style={{ float: "right", cursor: "pointer" }} onClick={handleInvoiceClose}>
            <CloseIcon />
          </Icon>
        </DialogTitle>
        <DialogContent>
          <MKTypography variant="h6" mb={2}>
            Order Summary
          </MKTypography>
          <MKBox display="flex" justifyContent="space-between" mb={1}>
            <MKTypography variant="button" fontWeight="regular" color="text">
              Trees Purchased:
            </MKTypography>
            <MKTypography variant="body2" fontWeight="medium">
              15
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="space-between" mb={1}>
            <MKTypography variant="button" fontWeight="regular" color="text">
              Price per Tree:
            </MKTypography>
            <MKTypography variant="body2" fontWeight="medium">
              $14
            </MKTypography>
          </MKBox>
          <MKBox display="flex" justifyContent="space-between" mb={1}>
            <MKTypography variant="button" fontWeight="regular" color="text">
              Total CO2 Offset:
            </MKTypography>
            <MKTypography variant="body2" fontWeight="medium">
              60kg
            </MKTypography>
          </MKBox>
          <Divider sx={{ my: 2 }} />
          <MKBox display="flex" justifyContent="space-between">
            <MKTypography variant="button" fontWeight="regular" color="text">
              Total:
            </MKTypography>
            <MKTypography variant="body2" fontWeight="medium">
              $210
            </MKTypography>
          </MKBox>
        </DialogContent>
        <DialogActions>
          <MKButton onClick={handleInvoiceClose} color="primary">
            Close
          </MKButton>
        </DialogActions>
      </Dialog>
    </MKBox>
  );
}

export default OrderInformation;
