import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, CardContent, CardMedia, LinearProgress } from "@mui/material";
import { Nature, Landscape, MonetizationOn } from "@mui/icons-material";
import { FaTree, FaLeaf } from "react-icons/fa";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import routes from "routes";
import MapComponent from "./MapComponent"; // Import the MapComponent

// Import your images here
import mapasForestCover from "assets/images/LasHuacas/Mapas/ForestCover.png";
import multitemporal20170425 from "assets/images/multitemporal/20170425.png";
import multitemporal20171229 from "assets/images/multitemporal/20171229.png";
import multitemporal20190418 from "assets/images/multitemporal/20190418.png";
import multitemporal20191227 from "assets/images/multitemporal/20191227.png";
import multitemporal20240426 from "assets/images/multitemporal/20240426.png";
import oakscan from "assets/images/OakScan.mp4";

function LasHuacasDashboard() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const docRef = doc(db, "userAccounts", currentUser.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();
            setUserData({
              user: {
                name: currentUser.displayName || userData.name,
                email: currentUser.email,
              },
              totalTrees: userData.treeQuantity || 0,
              totalSpent: userData.totalSpent || 0,
              co2Offset: userData.co2Offset || 0,
              trees: userData.trees || [],
            });
          } else {
            setError("No user data found");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setError("Failed to load dashboard data");
        } finally {
          setLoading(false);
        }
      } else {
        setError("User not authenticated");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handlePlantMoreTrees = () => {
    navigate("/pages/presentation/las-huacas");
  };

  if (loading) {
    return <MKTypography>Loading...</MKTypography>;
  }

  if (error) {
    return <MKTypography color="error">{error}</MKTypography>;
  }

  const treeSpecies = [
    {
      id: 1,
      name: "Guanacaste Tree",
      description:
        "The Guanacaste tree is native to Liberia, Guanacaste, Costa Rica. It is known for its large, umbrella-shaped canopy and its ability to provide shade.",
      co2Sequestration: 500,
      volume: 80,
      icon: FaTree,
      video: "https://player.vimeo.com/video/590882809",
    },
    {
      id: 2,
      name: "Oak Tree",
      description:
        "The Oak tree is a majestic species known for its strength and longevity. It provides habitat for various wildlife species and contributes to biodiversity.",
      co2Sequestration: 600,
      volume: 100,
      icon: FaLeaf,
      video: oakscan,
    },
  ];

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://www.creative-tim.com/product/material-kit-pro-react",
          label: "buy now",
          color: "info",
        }}
        sticky
      />
      <MKBox component="section" py={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <MKTypography variant="h4">Las Huacas</MKTypography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Nature />
                      <MKTypography variant="h6" ml={1}>
                        Total Trees Planted
                      </MKTypography>
                    </Box>
                    <MKTypography variant="h4">
                      {userData.totalTrees.toLocaleString()} Trees
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <Landscape />
                      <MKTypography variant="h6" ml={1}>
                        Total Carbon Offset
                      </MKTypography>
                    </Box>
                    <MKTypography variant="h4">{userData.co2Offset} Tons</MKTypography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <MonetizationOn />
                      <MKTypography variant="h6" ml={1}>
                        Total Spent
                      </MKTypography>
                    </Box>
                    <MKTypography variant="h4">
                      ${userData.totalSpent.toLocaleString()}
                    </MKTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <MKTypography variant="h5" mb={2}>
                  Tree Map
                </MKTypography>
                <MapComponent /> {/* Integrated MapComponent here */}
                <MKTypography variant="h6" mb={2}>
                  Tree Species
                </MKTypography>
                <Grid container spacing={2}>
                  {treeSpecies.map((species) => (
                    <Grid item xs={12} sm={6} key={species.id}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <CardMedia
                          component={species.video.endsWith(".mp4") ? "video" : "iframe"}
                          height="150"
                          src={species.video}
                          sx={{ pointerEvents: "none" }}
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                            <species.icon size={20} style={{ marginRight: "8px" }} />
                            <MKTypography variant="subtitle1" fontWeight="medium">
                              {species.name}
                            </MKTypography>
                          </Box>
                          <MKTypography variant="body2" color="text" fontSize="0.8rem">
                            {species.description}
                          </MKTypography>
                          <MKTypography variant="body2" color="text" mt={1} fontSize="0.8rem">
                            CO2 Sequestration: {species.co2Sequestration} kg/year
                          </MKTypography>
                          <MKTypography variant="body2" color="text" fontSize="0.8rem">
                            Volume: {species.volume} m³
                          </MKTypography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <MKTypography variant="h6">Tree Camera</MKTypography>
                    <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
                      <iframe
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        src="https://www.youtube.com/embed/F0GOOP82094?autoplay=1&mute=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <MKTypography variant="h6">Goal Progress</MKTypography>
                    <Box mt={2}>
                      <MKTypography variant="body2">Drone verification</MKTypography>
                      <LinearProgress
                        variant="determinate"
                        value={75}
                        sx={{
                          backgroundColor: "#e8f5e9",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#4caf50",
                          },
                        }}
                      />
                      <MKTypography variant="body2" mt={2}>
                        LiDAR verification
                      </MKTypography>
                      <LinearProgress
                        variant="determinate"
                        value={60}
                        sx={{
                          backgroundColor: "#e8f5e9",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#4caf50",
                          },
                        }}
                      />
                      <MKTypography variant="body2" mt={2}>
                        Satellite verification
                      </MKTypography>
                      <LinearProgress
                        variant="determinate"
                        value={90}
                        sx={{
                          backgroundColor: "#e8f5e9",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: "#4caf50",
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <MKTypography variant="h6">LiDAR Scans</MKTypography>
                <Box
                  component="img"
                  src={mapasForestCover}
                  alt="LiDAR Scan"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "0.75rem",
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <MKTypography variant="h6">Satellite Images</MKTypography>
                <Grid container spacing={1}>
                  {[
                    multitemporal20170425,
                    multitemporal20171229,
                    multitemporal20190418,
                    multitemporal20191227,
                    multitemporal20240426,
                  ].map((img, index) => (
                    <Grid item xs={4} key={index}>
                      <Box
                        component="img"
                        src={img}
                        alt={`Satellite Image ${index + 1}`}
                        sx={{
                          width: "100%",
                          height: "auto",
                          borderRadius: "0.75rem",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox mt={3}>
        <Card>
          <CardContent>
            <MKTypography variant="h5" mb={2}>
              Your Impact
            </MKTypography>
            <MKTypography variant="body1" mb={2}>
              Your contribution is making a significant impact on the environment. Keep up the great
              work!
            </MKTypography>
            <MKButton color="success" variant="contained" onClick={handlePlantMoreTrees}>
              Plant More Trees
            </MKButton>
          </CardContent>
        </Card>
      </MKBox>
    </>
  );
}

export default LasHuacasDashboard;
