import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAnVA5eZwbtuOsjy2wG_721yu9K94VuuYk",
  authDomain: "csequester-c75f2.firebaseapp.com",
  databaseURL: "https://csequester-c75f2.firebaseapp.com",
  projectId: "csequester-c75f2",
  storageBucket: "csequester-c75f2.appspot.com",
  messagingSenderId: "262402646199",
  appId: "1:262402646199:web:aeb0571658ec88367dad32",
};

const customDatabaseName = "csequesterdainamycs";

let app, auth, db;

try {
  console.log("Initializing Firebase with config:");
  app = initializeApp(firebaseConfig);
  console.log("Firebase app initialized successfully");

  auth = getAuth(app);
  console.log("Firebase Auth initialized");

  // Initialize Firestore with the custom database name
  db = getFirestore(app, customDatabaseName);
  console.log(`Firestore initialized with database: ${customDatabaseName}`);

  // Enable offline persistence for Firestore
  enableIndexedDbPersistence(db)
    .then(() => {
      console.log("Firestore offline persistence enabled");
    })
    .catch((err) => {
      if (err.code === "failed-precondition") {
        console.warn("Multiple tabs open, persistence can only be enabled in one tab at a time.");
      } else if (err.code === "unimplemented") {
        console.warn(
          "The current browser does not support all of the features required to enable persistence"
        );
      } else {
        console.error("Error enabling Firestore persistence:", err);
      }
    });

  // Test Firestore connection
  db.collection("test")
    .doc("connection")
    .set({ test: true })
    .then(() => console.log("Firestore write test successful"))
    .catch((error) => {
      console.error("Firestore write test failed:", error);
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
    });
} catch (error) {
  console.error("Error initializing Firebase:", error);
  console.error("Error details:", JSON.stringify(error, null, 2));

  if (error.code === "permission-denied") {
    console.error("Firebase permission denied. Check your security rules and API key permissions.");
  } else if (error.code === "unavailable") {
    console.error(
      "Firebase services are currently unavailable. Check your internet connection or Firebase status."
    );
  }
}

export { auth, db };
